<!--  -->
<template>
    <div class="stu">
      <el-card class="box-card">
        <div>
            XX班课：创建人XX、辅导老师XX/YY/ZZ、学习进展XX%（已学条目/课程总条目）、开班时间、结班时间、班级学生人数XX、已布置作业次数XX、作业内题目数试满分XX）

        </div>
        <div>
          <el-row :gutter="21" style="margin-top: 20px">
            <el-col :span="24">
              <diy-echarts
                class="border-base"
                v-bind="echartsData.timeData1"
              ></diy-echarts>
            </el-col>
            
          </el-row>
          <el-row :gutter="21" style="margin-top: 20px">
            <el-col :span="24">
              <diy-echarts
                class="border-base"
                v-bind="echartsData.timeData1"
              ></diy-echarts>
            </el-col>
            
          </el-row>
         
          <el-row :gutter="21" style="margin-top: 20px">
            <el-col :span="12">
              <diy-echarts
                class="border-base"
                v-bind="echartsData.questTypeData"
              ></diy-echarts>
            </el-col>
            <el-col :span="12">
              <diy-echarts
                class="border-base"
                v-bind="echartsData.acData"
              ></diy-echarts>
            </el-col>
          </el-row>
        </div>
  
        <div class="table" style="margin-top: 20px" v-loading="loading">
          <el-table
            ref="multipleTable"
            :data="tableData"
            tooltip-effect="dark"
            style="width: 100%"
            :row-key="(row) => row.id"
          >
            <el-table-column label="排名" align="center">
              <template slot-scope="scope">
                <span class="ProblemList-name">{{
                  scope.row.name
                }}</span></template
              >
            </el-table-column>
            <el-table-column label="学校-姓名" align="center">
              <template slot-scope="scope">
                <span>{{ scope.row.user_realname }}</span>
              </template>
            </el-table-column>
            <el-table-column label="出勤率" align="center">
              <template slot-scope="scope"
                >{{ scope.row.member_count }}人</template
              >
            </el-table-column>
            <el-table-column label="提交作业率" align="center">
              <template slot-scope="scope">{{
                scope.row.type_name ? scope.row.type_name : "--"
              }}</template>
            </el-table-column>
            <el-table-column label="提交题目率" align="center">
              <template slot-scope="scope">{{
                scope.row.create_time_format
              }}</template>
            </el-table-column>
            <el-table-column label="编程题目全AC率" align="center">
              <template slot-scope="scope">{{
                scope.row.create_time_format
              }}</template>
            </el-table-column>
            <el-table-column label="一次提交结果全AC率" align="center">
              <template slot-scope="scope">{{
                scope.row.create_time_format
              }}</template>
            </el-table-column>
            <el-table-column label="考试1成绩" align="center">
              <template slot-scope="scope">{{
                scope.row.create_time_format
              }}</template>
            </el-table-column>
            <el-table-column label="考试2成绩" align="center">
              <template slot-scope="scope">{{
                scope.row.create_time_format
              }}</template>
            </el-table-column>
           
            <el-table-column label="排名得分" align="center">
              <template slot-scope="scope">{{
                scope.row.create_time_format
              }}</template>
            </el-table-column>
          </el-table>
        </div>
        <div class="pagination" style="margin-top: 20px">
          <el-pagination
            background
            layout="prev, pager, next"
            :total="total"
            @current-change="currentChange"
            :page-size="pageSize"
            :current-page="page"
            :hide-on-single-page="true"
          >
          </el-pagination>
        </div>
      </el-card>
    </div>
  </template>
    
    <script>
  import DiyEcharts from "@/components/com/el/diyEcharts";
  var dayjs = require("dayjs");
  export default {
    //import引入的组件需要注入到对象中才能使用
    components: {
      DiyEcharts,
    },
    data() {
      //这里存放数据
      return {
        loading: false,
        page: 1,
        pageSize: 10,
        total: 0,
        tableData: [
          {
            date: "2016-05-02",
            name: "王小虎",
            address: "上海市普陀区金沙江路 1518 弄",
          },
          {
            date: "2016-05-04",
            name: "王小虎",
            address: "上海市普陀区金沙江路 1517 弄",
          },
          {
            date: "2016-05-01",
            name: "王小虎",
            address: "上海市普陀区金沙江路 1519 弄",
          },
          {
            date: "2016-05-03",
            name: "王小虎",
            address: "上海市普陀区金沙江路 1516 弄",
          },
        ],
        echartsData: {
          questTypeData: {
            name: "测试点评测概况",
            data: [
              [
                { value: 1, name: "非AC测试点" },
                { value: 2, name: "AC测试点" },
              ],
            ],
          },
          acData: {
            name: "全AC题目标签",
            data: [
              [
                { value: 1, name: "非AC测试点" },
                { value: 2, name: "AC测试点" },
              ],
            ],
          },
          knowData: {
            name: "全评测题目标签",
            data: [
              [
                { value: 1, name: "非AC测试点" },
                { value: 2, name: "AC测试点" },
              ],
            ],
          },
          timeData1: {
            name: "成员做题时间分布1",
            type: "zhu",
            options: { labels: [] },
            data: [[
              { value: 0, name: "1点" },
              { value: 1, name: "2点" },
              { value: 5, name: "3点" },
              { value: 4, name: "4点" },
              { value: 7, name: "5点" },
              { value: 4, name: "6点" },
          ]],
          },
          timeData2: {
            name: "成员做题时间分布2",
            type: "zhu",
            options: { labels: [] },
            data: [[{ value: 0, name: "1点" }]],
          },
          timeData3: {
            name: "成员做题时间分布3",
            type: "zhu",
            options: { labels: [] },
            data: [[{ value: 0, name: "1点" }]],
          },
        },
      };
    },
    //监听属性 类似于data概念
    computed: {},
    //监控data中的数据变化
    watch: {},
    //生命周期 - 创建完成（可以访问当前this实例）
    created() {},
    //方法集合
    methods: {
      // 分页
      currentChange(e) {
        this.page = e;
        //   this.getTeamList();
      },
    },
    //生命周期 - 挂载完成（可以访问DOM元素）
    mounted() {},
  };
  </script>
    <style  lang='less' scoped>
  .stu {
    .box-card {
      margin-top: 20px;
      ::v-deep {
        .border-base {
          text-align: center;
          border-radius: 20px;
          padding: 10px;
          border: 1px solid #ccc;
        }
      }
    }
  }
  </style>